import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../library/button/button';
import { AngleLeftLightIcon } from '../../shared/icons/icons';

import './back-button.scss';

function BackButton(props) {
  const { className, href, onClick, text } = props;

  return (
    <Button
      className={className}
      href={href}
      onClick={() => {
        if (onClick) onClick();
      }}
      styleName="back-button"
      type="button"
    >
      <AngleLeftLightIcon aria-hidden="true" styleName="back-icon" />
      {text}
    </Button>
  );
}

BackButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
};

export default BackButton;
