import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FitmentTireSizeHelpGuide from './components/fitment-tire-size-help-guide/fitment-tire-size-help-guide';
import FitmentTireSizeOption from './components/fitment-tire-size-option/fitment-tire-size-option';
import OptionalSizes from './components/optional-sizes/optional-sizes';
import OptionalSizesHeading from './components/optional-sizes-heading/optional-sizes-heading';
import { InfoCircleLightIcon } from '../../../../../../shared/icons/icons';
import Button from '../../../../../button/button';
import Collapsible from '../../../../../collapsible/collapsible';
import { EVENTS } from '../../../../fitment-constants';
import { toFitmentSize } from '../../../../fitment-utils';
import {
  assemblyPropType,
  modelPropType,
  trimPropType
} from '../../fitment-by-vehicle-util';
import FitmentStepContainer from '../fitment-step-container/fitment-step-container';

import { useFitment } from '../../../../../../shared/hooks/hooks';

import { parseTireSize } from '../../../../../../shared/utils/fitment';
import { isArrayWithLength } from '../../../../../../shared/utils/validators';

import './fitment-tire-size.scss';

function byOffset(a, b) {
  return parseFloat(a.offset) - parseFloat(b.offset);
}

function getParam(assembly, model, trim, rideHeight) {
  const params = {
    assemblyId: assembly.id,
    trimId: trim.id,
    vehicleId: model.id
  };

  if (rideHeight) {
    params.rideHeight = {
      description: rideHeight?.description,
      id: rideHeight?.id,
      type: rideHeight?.type
    };
  }

  return params;
}

const AXLE_TYPES = {
  BOTH: 'B'
};

function FitmentTireSize(props) {
  const {
    assemblies,
    getOptionalSizes,
    onShopProducts,
    selectedAssembly,
    selectedFitment,
    selectedModel,
    selectedRideHeight,
    selectedTrim,
    selectedYear,
    setSelectedAssembly,
    onEvent,
    renderFitmentEdit,
    renderShopProductsButton,
    shouldFilterOptionalOESize = true,
    showShopProductsButton = true,
    shouldCallShopProducts,
    isEditing,
    isYearAfterModel,
    textOverrides
  } = props;
  const [optionalSizes, setOptionalSizes] = useState(null);
  const [isOptionalSizesOpen, setIsOptionalSizesOpen] = useState(false);
  const [selectedOptionalSize, setSelectedOptionalSize] = useState(null);
  const [selectedOptionalSizeGrouping, setSelectedOptionalSizeGrouping] =
    useState(null);
  const { isLockedFitment } = useFitment();
  const shouldRenderFitmentEdit =
    isLockedFitment && renderFitmentEdit && isEditing;
  const { standardAssemblies, staggeredAssemblies } = Array.isArray(assemblies)
    ? assemblies.reduce(
        (grouping, option) => {
          if (/^F:?\s/.test(option?.description)) {
            const [frontTireDescription, rearTireDescription] =
              option?.description?.split(/\s*-\s*R:?\s*/) ?? [];

            grouping.staggeredAssemblies.push({
              description: option?.description,
              frontTireDescription: frontTireDescription?.replace(
                /^F:?\s*/,
                ''
              ),
              id: option?.id,
              rearTireDescription
            });
          } else {
            grouping.standardAssemblies.push(option);
          }

          return grouping;
        },
        { staggeredAssemblies: [], standardAssemblies: [] }
      )
    : { staggeredAssemblies: null, standardAssemblies: null };

  const hasStockRideHeight = /stock height/i.test(
    selectedRideHeight?.description
  );
  const originalTireSizesCount =
    (Array.isArray(standardAssemblies) ? standardAssemblies.length : 0) +
    (Array.isArray(staggeredAssemblies) ? staggeredAssemblies.length : 0);
  const optionalSizesToggleLabel =
    !selectedRideHeight || hasStockRideHeight
      ? `see ${isOptionalSizesOpen ? 'fewer' : 'more'} tire sizes`
      : `${selectedRideHeight?.type?.toLowerCase()} tire sizes`;
  let subHeading =
    'This vehicle has one original tire size. The pre-selected size determines the optional sizes available.';

  if (originalTireSizesCount > 1) {
    if (selectedRideHeight && !hasStockRideHeight) {
      subHeading = `We need you to select your original tire size to show you ${selectedRideHeight.type?.toLowerCase()} tire options.`;
    } else {
      subHeading =
        'This vehicle has multiple original tire sizes. Selecting one below will determine the optional sizes available.';
    }
  }

  const onAssemblySelected = useCallback(
    selectedAssembly => {
      getOptionalSizes(
        getParam(
          selectedAssembly,
          isYearAfterModel ? selectedYear : selectedModel,
          selectedTrim,
          selectedRideHeight
        )
      ).then(optionalSizeData => {
        const optionalSizes = [];
        let selectedFrontTireSize;
        let selectedRearTireSize;
        let selectedTireSize;

        if (selectedAssembly.frontTireSize) {
          selectedFrontTireSize = parseTireSize(
            selectedAssembly.frontTireDescription
          );
          selectedRearTireSize = parseTireSize(
            selectedAssembly.rearTireDescription
          );
        } else {
          selectedTireSize = parseTireSize(selectedAssembly.description);
        }

        function addTireSize(
          wheelSize,
          tireSize,
          offset,
          isStaggered,
          matchQualifier,
          diameter
        ) {
          let matchingGrouping = optionalSizes.find(
            group => group.offset === offset
          );
          if (!matchingGrouping) {
            matchingGrouping = {
              offset,
              oversizedSizes: [],
              oversizedSizesStaggered: [],
              sizes: [],
              sizesStaggered: [],
              undersizedSizes: [],
              undersizedSizesStaggered: [],
              wheelSize
            };
            optionalSizes.push(matchingGrouping);
          }
          if (matchingGrouping.wheelSize === diameter) {
            let sizeArrayKey;
            if (matchQualifier === 'undersize') {
              sizeArrayKey = 'undersizedSizes';
            } else if (matchQualifier === 'match') {
              sizeArrayKey = 'sizes';
            } else {
              sizeArrayKey = 'oversizedSizes';
            }

            if (isStaggered) {
              sizeArrayKey += 'Staggered';
            }

            let sizeArray = matchingGrouping[sizeArrayKey];

            if (!sizeArray.find(size => size.id === tireSize.id)) {
              sizeArray.push(tireSize);
            }
          }
        }

        optionalSizeData.tireSizes?.forEach(item => {
          const wheelSize = parseFloat(item.wheelSize);
          const offset = item.sizeQualifier;

          if (
            optionalSizes.find(group => group.offset === offset) ||
            item.axleType !== AXLE_TYPES.BOTH
          ) {
            return;
          }

          item.optionalTireSizes.forEach(optionalTireSize => {
            const tireSize = {
              description: optionalTireSize.tireSize,
              id: `optional-size-${optionalTireSize.tireSize}`
            };

            const size = parseTireSize(tireSize.description);

            if (
              !shouldFilterOptionalOESize ||
              selectedTireSize?.diameter !== size?.diameter ||
              selectedTireSize?.aspectRatio !== size?.aspectRatio ||
              selectedTireSize?.width !== size?.width
            ) {
              addTireSize(
                String(wheelSize),
                tireSize,
                offset,
                false,
                optionalTireSize.matchQualifier,
                size?.diameter
              );
            }
          });
        });

        optionalSizeData.optionalStaggeredSet?.forEach(item => {
          const tireSize = {
            frontTireDescription: item.size.front,
            id: `optional-size-${item.size.front}-${item.size.rear}`,
            rearTireDescription: item.size.rear
          };
          const frontTireSize = parseTireSize(tireSize.frontTireDescription);
          const rearTireSize = parseTireSize(tireSize.rearTireDescription);

          const wheelSize = parseFloat(frontTireSize.diameter);
          const offset = item.offset;

          if (
            !shouldFilterOptionalOESize ||
            selectedFrontTireSize?.diameter !== frontTireSize?.diameter ||
            selectedFrontTireSize?.aspectRatio !== frontTireSize?.aspectRatio ||
            selectedFrontTireSize?.width !== frontTireSize?.width ||
            selectedRearTireSize?.diameter !== rearTireSize?.diameter ||
            selectedRearTireSize?.aspectRatio !== rearTireSize?.aspectRatio ||
            selectedRearTireSize?.width !== rearTireSize?.width
          ) {
            addTireSize(
              String(wheelSize),
              tireSize,
              String(offset),
              true,
              item.matchQualifier,
              frontTireSize?.diameter
            );
          }
        });

        optionalSizes.sort(byOffset);
        setOptionalSizes(optionalSizes);

        if (selectedFitment?.isOE || selectedAssembly.isOE) {
          return;
        }
        if (!isEditing) return;

        const matchingOptionalSizeGroup = optionalSizes.find(
          group => group.offset === selectedFitment?.sizeQualifier
        );

        if (matchingOptionalSizeGroup) {
          let matchingOptionalSize;

          if (selectedFitment?.rearTireSize) {
            for (const sizes of [
              matchingOptionalSizeGroup.sizesStaggered,
              matchingOptionalSizeGroup.undersizedSizesStaggered,
              matchingOptionalSizeGroup.oversizedSizesStaggered
            ]) {
              const match = sizes.find(
                size =>
                  toFitmentSize(size.frontTireDescription) ===
                    selectedFitment?.frontTireSize &&
                  toFitmentSize(size.rearTireDescription) ===
                    selectedFitment?.rearTireSize
              );
              if (match) {
                matchingOptionalSize = match;
                break;
              }
            }
          } else {
            for (const sizes of [
              matchingOptionalSizeGroup.sizes,
              matchingOptionalSizeGroup.undersizedSizes,
              matchingOptionalSizeGroup.oversizedSizes
            ]) {
              const match = sizes.find(
                size =>
                  toFitmentSize(size.description) ===
                  selectedFitment?.frontTireSize
              );
              if (match) {
                matchingOptionalSize = match;
                break;
              }
            }
          }

          if (matchingOptionalSize) {
            setSelectedOptionalSizeGrouping(matchingOptionalSizeGroup);
            setSelectedOptionalSize(matchingOptionalSize);
            setIsOptionalSizesOpen(true);
          }
        }
      });
    },
    [
      getOptionalSizes,
      isEditing,
      selectedFitment,
      selectedModel,
      selectedRideHeight,
      selectedTrim,
      selectedYear,
      isYearAfterModel,
      shouldFilterOptionalOESize
    ]
  );

  const selectAssembly = useCallback(
    assembly => {
      setSelectedAssembly(assembly);
      setSelectedOptionalSize(null);
      setSelectedOptionalSizeGrouping(null);
    },
    [setSelectedAssembly]
  );

  const shopProducts = useCallback(
    () =>
      onShopProducts(
        selectedAssembly,
        selectedOptionalSize,
        selectedOptionalSizeGrouping?.offset
      ),
    [
      onShopProducts,
      selectedAssembly,
      selectedOptionalSize,
      selectedOptionalSizeGrouping
    ]
  );

  useEffect(() => {
    if (selectedAssembly && selectedTrim && selectedModel) {
      onAssemblySelected(selectedAssembly);
    } else {
      setSelectedOptionalSize(null);
    }
  }, [onAssemblySelected, selectedAssembly, selectedTrim, selectedModel]);

  useEffect(() => {
    if (shouldCallShopProducts) {
      shopProducts();
      onEvent({
        name: EVENTS.SHOP_PRODUCTS_CALLED
      });
    }
  }, [shouldCallShopProducts, shopProducts, onEvent]);

  return shouldRenderFitmentEdit ? (
    renderFitmentEdit({
      ...props,
      onUpdateVehicle: shopProducts,
      optionalSizes: optionalSizes,
      selectedOptionalSize: selectedOptionalSize,
      selectedOptionalSizeGrouping: selectedOptionalSizeGrouping,
      setSelectedOptionalSize: setSelectedOptionalSize,
      setSelectedOptionalSizeGrouping: setSelectedOptionalSizeGrouping
    })
  ) : (
    <FitmentStepContainer
      heading="What’s the original tire size for your vehicle?"
      moreInfoContent={() => <FitmentTireSizeHelpGuide />}
      moreInfoHeading={() => (
        <>
          <InfoCircleLightIcon /> need help?
        </>
      )}
      styleName="fitment-tire-size"
      subHeading={subHeading}
    >
      <div role="radiogroup">
        {Array.isArray(standardAssemblies) &&
          standardAssemblies.map((assembly, i) => (
            <FitmentTireSizeOption
              assembly={{ ...assembly, isOE: true }}
              isSelected={selectedOptionalSize ? false : void 0}
              key={`standard-size-${assembly.description}-${i}`}
              onClick={selectAssembly}
              selectedAssembly={selectedAssembly}
            />
          ))}

        {Array.isArray(staggeredAssemblies) && (
          <>
            <br />
            {staggeredAssemblies.map((assembly, i) => (
              <FitmentTireSizeOption
                assembly={{ ...assembly, isOE: true }}
                isSelected={selectedOptionalSize ? false : void 0}
                key={`standard-size-staggered-${assembly.description}-${i}`}
                onClick={selectAssembly}
                selectedAssembly={selectedAssembly}
              />
            ))}
          </>
        )}
      </div>

      {isArrayWithLength(optionalSizes) && (
        <>
          <OptionalSizesHeading selectedRideHeight={selectedRideHeight} />
          <Collapsible
            isOpen={isOptionalSizesOpen}
            label={optionalSizesToggleLabel}
            onToggle={setIsOptionalSizesOpen}
          >
            <OptionalSizes
              isStaggered={selectedFitment?.isStaggered}
              optionalSizes={optionalSizes}
              selectedAssembly={selectedAssembly}
              selectedOptionalSize={selectedOptionalSize}
              selectedOptionalSizeGrouping={selectedOptionalSizeGrouping}
              selectedRideHeight={selectedRideHeight}
              setSelectedOptionalSize={setSelectedOptionalSize}
              setSelectedOptionalSizeGrouping={setSelectedOptionalSizeGrouping}
            />
          </Collapsible>
        </>
      )}

      {showShopProductsButton && (
        <>
          <hr className="fitment-tire-size__cta-divider" />
          {renderShopProductsButton ? (
            renderShopProductsButton({
              isDisabled: !selectedAssembly,
              isEditing: isEditing,
              onClick: shopProducts
            })
          ) : (
            <Button
              isDisabled={!selectedAssembly}
              isFullWidth
              isPrimary
              onClick={shopProducts}
            >
              {textOverrides?.fitmentContinueCta ?? 'Shop Products'}
            </Button>
          )}
        </>
      )}
    </FitmentStepContainer>
  );
}

FitmentTireSize.propTypes = {
  assemblies: PropTypes.arrayOf(assemblyPropType).isRequired,
  getOptionalSizes: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isYearAfterModel: PropTypes.bool,
  onEvent: PropTypes.func,
  onShopProducts: PropTypes.func,
  renderFitmentEdit: PropTypes.func,
  renderShopProductsButton: PropTypes.func,
  selectedAssembly: assemblyPropType,
  selectedCrumb: PropTypes.string,
  selectedFitment: PropTypes.shape({
    frontTireSize: PropTypes.string,
    isOE: PropTypes.bool,
    isStaggered: PropTypes.bool,
    rearTireSize: PropTypes.string,
    sizeQualifier: PropTypes.string
  }),
  selectedModel: modelPropType,
  selectedRideHeight: PropTypes.object,
  selectedTrim: trimPropType,
  selectedYear: PropTypes.any,
  setSelectedAssembly: PropTypes.func,
  shouldCallShopProducts: PropTypes.bool,
  shouldFilterOptionalOESize: PropTypes.bool,
  showShopProductsButton: PropTypes.bool,
  textOverrides: PropTypes.shape({
    fitmentContinueCta: PropTypes.string
  })
};

export default FitmentTireSize;
